import axios from "axios";
import { useStore } from "@/store/loading.js";
import { useCookies } from "vue3-cookies";
import { showToast } from "@/util/toast";
import router from "@/router";
import { baseURL } from "@/util/base-url-config";

const { cookies } = useCookies();

// const token  = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjM3MGRjYTYwZTk5NDJmYWI5NTg4NjYyZWNkZGQ0Y2JkNGZiMzgwNzdiYzVmMTc3YmI5NzQ0ZGVlOGJhN2Y2M2M0N2I5ODkyM2Q1OTE3M2EiLCJpYXQiOjE3MzA1MzUxNTQuOTI4NTYyLCJuYmYiOjE3MzA1MzUxNTQuOTI4NTY0LCJleHAiOjE3NDYxNzM1NTQuOTE4NzE1LCJzdWIiOiIxMTIiLCJzY29wZXMiOltdfQ.Tj7w46qPiPtoTY1DO49Zs9i84Nw5_qUvvguvqsOmoBm6b6YlUQuj_y59byyoFyHy76PIk-8Ao9QLiWkCm9sL82_aBIMNPaJw8BE2BPDFUGObKWPBbf6Jo2Vf261kBSS55slgJwi4Mxl9Qcgs6bgOibRBTWSVZ6fIn4HyGCKDxz8yjlQ5q4nQ9nboSZdUfQbvG6627qYKkGzUBbwMpD-wLe0uA5a39F0IDconS--SlmSMM1eHOqL4tz7NvU6DwHvX71H86PdM6MNGwxbNdtZyknIK3_ROrUdI4--SMuiFWmNYJADTvhqo3czPRAml4Pf-m7m_O5P1fArwYIpCP4m-VB5PgoMXzejP_3zY0GsobOZ6O_TRX2BOsHIYcIc5eJcAeTex-U9DHFKloxEsfTSDbO21LydXd_BTdINSPwjfxy8lLxyVn7ZYExgCtUrFQbCJmFe-jgrl1ym0WlbJtVYdKxDLGjBd2H9PQRTwO4_aWhsJjwxkPJ8T3vcRqaR0hL6iQyMfgLVhYBVN6ZVJNvIUYz6Fl6FWU3Dzb6PBg45MP9ShRGSaZyJiNRGIGhhDSsWpcsJV95o1QVATIIid68nNBShuvsduXdba8RAJkhvzQGbQsiDq6rDRyN00PxqF85214EwICV8r8lw9SmjOJsXjt3hqAJWOGJNA09jVsfPcgk8'
// cookies.set("token", token, '1y', '1y'); 

const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    "X-CLIENT-ID": +process.env.VUE_APP_CLIENT_ID,
    "X-CLIENT-SECRET": process.env.VUE_APP_NOT_SECRET_CODE,
  },
});

export const httpClientWithoutLoading = axios.create({
  baseURL: baseURL,
  headers: {
    "X-CLIENT-ID": +process.env.VUE_APP_CLIENT_ID,
    "X-CLIENT-SECRET": process.env.VUE_APP_NOT_SECRET_CODE,
  },
});

httpClientWithoutLoading.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

// eslint-disable-next-line no-unused-vars
let numberOfAjaxCAllPending = 0;

httpClient.interceptors.request.use(
  (config) => {
    const store = useStore();
    numberOfAjaxCAllPending++;
    store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
    store.mutationer({ isLoading: true });
    const token = localStorage.getItem("token"); // cookies.get("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

httpClient.interceptors.response.use(
  (response) => {
    const store = useStore();

    numberOfAjaxCAllPending--;
    store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
    // eslint-disable-next-line eqeqeq
    if (numberOfAjaxCAllPending == 0) {
      store.mutationer({ isLoading: false });
    }
    if (response.data) return response.data;
    else return response;
  },
  (error) => {
    const store = useStore();

    if (numberOfAjaxCAllPending > 0) numberOfAjaxCAllPending--;
    store.mutationer({ isLoading: false });
    store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
    // toast(error?.response?.data?.message || error?.response?.data?.result?.message || error?.response?.data?.data?.message, toastConfig)
    showToast({
      message:
        error?.response?.data?.message ||
        error?.response?.data?.result?.message ||
        error?.response?.data?.data?.message,
      type: "error",
    });
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      cookies.remove("token");
      router.push({ name: "home" });
    }
    throw error;
  },
);

export default httpClient;
