<template>
  <div
    class="qooBox-main flex flex-col overflow-hidden"
    :style="{ height: `${windowsHeight}px` }"
  >
    <MainHeader v-if="$route.meta.layout == 'default'" />
    <div
      class="grow flex flex-col"
      :class="{ 'router-view-content': $route.meta.layout == 'default' }"
      :style="{ height: `${windowsHeight - 96}px` }"
    >
      <router-view />
    </div>
  </div>
  <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import MainHeader from "@/components/base/MainHeader";
import { computed, onMounted, watch } from "vue";
import { useStore } from "@/store/loading.js";
import { useWindowSize } from "@vueuse/core";
import { login } from "@/services/axios/login.service.js";

const { height: windowsHeight } = useWindowSize();

const store = useStore();

const isLoading = computed(() => {
  return store.isLoading;
});

const sendMail = async () => {
  try {
    const token = localStorage.getItem("token"); // cookies.get("token");
    if (!token) {
      const mail = {
        email: "default-user@default.com",
      };
      store.isLoading = true;
      const { data } = await login(mail);
      console.log("data", data);
      if (data.token) {
        // cookies.set("token", data.token, '1y', '1y');
        await localStorage.setItem("token", data.token);
        setTimeout(() => {
          let testToken = localStorage.getItem("token");
          console.log('token--', testToken);
          
          localStorage.setItem("email", mail.email);
          localStorage.setItem("user_type", "Customer"); // Customer or Broker
          localStorage.setItem("status", data.status);
          localStorage.setItem("organization_id", data.organization);
          localStorage.setItem("project_id", data.project);
          store.accessToken = data.token;
          store.isAgent = false;
        }, 200);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  if (store.numberOfAjaxCAllPending == 0) {
    store.mutationer({ isLoading: false });
  }
  sendMail()
});
</script>

<style>
/* .router-view-content {
  padding-top: 6rem;
}

@media screen and (max-width: 480px) {
  .router-view-content {
    padding-top: 6.5rem;
  }
} */

/* @media (min-width: 480px) and (max-width: 768px) {
  .router-view-content {
    padding-top: 6.6rem;
  }
} */
.g {
  color: #ef45234d;
}
</style>
